import { useMemo } from "react";
import { usePageRoutable } from "@tecnobit-srl/routable-types-page/lazy";
import { useDownloadRoutable } from "@tecnobit-srl/routable-types-download/lazy";
const useCoreRoutableTypes = () => {
  const page = usePageRoutable();
  const download = useDownloadRoutable();
  return useMemo(
    () => ({
      ...page,
      ...download
    }),
    [page, download]
  );
};
export {
  useCoreRoutableTypes
};
